/* global loadJS:true jsDirectoryUri:true Site:true */
loadJS(jsDirectoryUri + '/bundle.min.js', function loadJSCallback() {
  if (document.readyState === 'interactive') {
    Site.init();
  } else {
    window.addEventListener('DOMContentLoaded', function DOMContentLoaded() {
      Site.init();
    }, false);
  }
});
